var config = {
                //apiURL: "https://localhost:44393/"
        //apiURL: "https://poolside.techlife.us:8877/"
        //apiURL: "https://poolside.techlife.us:8082/"
        apiURL: "https://api.scan4menu.net/",
        rootURL: "https://test.scan4menu.net/",
        stripePublicKey: 'pk_live_51GxPmEJLNTfrNCNLTQofWwZxCkt1NKOYJ7gbmWPxs5bjzdtklBD9xfkw5eHJsTIqftJqEOAa90tKRyMSh5FyujCO001ND9u6tw',
        googleKey: 'AIzaSyAyJBkMAezeOaPPp7BC1aU4CmxNJ6lIP0s',
        //apiURL: "http://localhost:52436/",
        //rootURL: "http://localhost:3000/",
        //stripePublicKey: 'pk_test_51GxPmEJLNTfrNCNLdS8zPurueHGn5vhY4g9ij14bDxXeuVg3sMgt5Ctuwhb7yT3lGMrt66rsJRfmpNVcVk3cipP700W6gsFoyz',
        //googleKey: 'AIzaSyAGzQNexLnikFieWcEgW3D7EqIc2zWtuT0',
        pusherAppKey: '80256340d93522963f55',
        pusherCluster: 'ap1'
        
};

export default config;